import React, { useEffect, useState } from 'react';
import { Warehouse, DoorClosed } from 'lucide-react';
import { api_bd_nr } from '../../services/Api';

import { useRecoilValue } from 'recoil'
import { profileData } from '../../atoms/user.atom'
import SwipeToConfirm from './SwipeToConfirm';
import LongPressButton from '../LongPressButton';


function DeskAutomation() {
    const profile_user = useRecoilValue(profileData)
    const [controllerGarageState, setControllerGarageState] = useState({})

    const [wareHouseBtn, setWareHouseBtn] = useState({
        label: "Portão veicular",
        disabled: false
    })

    const [doorClosedBtn, setDoorClosedBtn] = useState({
        label: "Portão pedestre",
        disabled: false
    })

    const [autoClose, setAutoClose] = useState(false)

    const handleError = (option, error) => {

        let message = '"Ocorreu um erro aguarde e tente novamente"'
        if (error?.response?.data?.error) {
            if (error.response.status === 405) {
                message = 'NÃO AUTORIZADO!'
            } else if (error.response.status === 408) {
                message = 'Sem resposta do servidor, tente novamente em alguns segundos.'
            }

        }

        if (option === 'p1') {
            setTimeout(() => {
                setWareHouseBtn(old => {
                    return { ...old, label: "Portão veicular", disabled: false }
                })
            }, 4000)
            setWareHouseBtn(old => {
                return { ...old, label: message, disabled: true }
            })
        } else {
            setTimeout(() => {
                setDoorClosedBtn(old => {
                    return { ...old, label: "Portão pedestre", disabled: false }
                })
            }, 4000)
            setDoorClosedBtn(old => {
                return { ...old, label: message, disabled: true }
            })
        }
    }

    const handleOK = (option, data) => {
        if (option === 'p1') {
            setTimeout(() => {
                setWareHouseBtn(old => {
                    return { ...old, label: controllerGarageState?.status === 'online' ? "Portão veicular" : "Controlador do portão offline", disabled: false }
                })
            }, 1000)

            setWareHouseBtn(old => {
                return { ...old, label: "OK!", disabled: true }
            })

        } else {
            setTimeout(() => {
                setDoorClosedBtn(old => {
                    return { ...old, label: controllerGarageState?.status === 'online' ? "Portão pedestre" : "Controlador do portão offline", disabled: false }
                })
            }, 1000)

            setDoorClosedBtn(old => {
                return { ...old, label: "OK!", disabled: true }
            })
        }



    }

    useEffect(() => {
        api_bd_nr.get('/icontrollerstates').then(resp => {
            const garageState = resp.data.find(el => el.name === "IGARAGE1") || {}
            setControllerGarageState(garageState)
        })
    }, [])

    return (
        <div className='flex flex-col w-4/4'>
            <div className='flex flex-row items-center gap-1'>
                <span className='bg-white font-bold'>MENU DE AUTOMAÇÃO DO ESCRITÓRIO</span>
            </div>

            {/* PORTÃO VEICULAR */
                (profile_user?.desk_permissions?.entrance_option?.filter(el => el === 'p1')?.length > 0 ? true : false) &&
                <div
                    className={`flex flex-col items-start gap-1 mt-5 p-3 ${wareHouseBtn.disabled === true ? 'text-zinc-400' : ''}`}
                    style={{
                        paddingBottom: 15,
                        borderBottom: profile_user?.desk_permissions?.entrance_option.length === 2 ? '1px solid #c3c3c3' : 'none'
                    }}
                >
                    <div className='flex flex-row w-full flex-wrap items-center px-10 mb-5 justify-between'>
                        <div className='flex flex-row items-center justify-start min-w-[200px]'>
                            {wareHouseBtn.disabled === false &&
                                <Warehouse size={20} className={`min-w-[20px] ${controllerGarageState?.status === 'online' ? 'text-intec-500' : 'text-red-500'}`} />
                            }
                            {wareHouseBtn.disabled === true &&
                                <div className="loader2 border-4 border-intec-500 border-t-white w-6 h-6 ml-3 mr-0 rounded-full"></div>
                            }
                            <span className={`bg-white ml-3 text-xl ${controllerGarageState?.status === 'online' ? 'text-intec-500' : 'text-red-500'}`}>{controllerGarageState?.status === 'online' ? wareHouseBtn.label : 'Controlador do portão offline.'}</span>
                        </div>

                        <div className='flex flex-row items-center justify-start md:justify-end mt-5 md:mt-0  min-w-[200px]'>
                            <input
                                className='scale-110'
                                type='checkbox'
                                value={autoClose}
                                onChange={(e) => {
                                    setAutoClose(e.target.checked)
                                }}
                            />
                            <span className='text-xl'>Fechamento Rápido (Auto.)</span>
                        </div>
                    </div>
                    <SwipeToConfirm
                        disabled={wareHouseBtn.disabled}
                        onOk={() => {
                            if (wareHouseBtn.disabled === true) {
                                return
                            }

                            setWareHouseBtn(old => {
                                return { ...old, label: "Acionando, aguarde...", disabled: true }
                            })

                            api_bd_nr.post(`/igarage`, {
                                idUser: profile_user.id,
                                opt: 'p1',
                                autoClose
                            }).then(resp => {
                                handleOK('p1', resp.data)
                            }).catch(error => {
                                handleError('p1', error)
                            })
                        }}
                    />
                </div>
            }

            {/* PORTÃO INDIVIDUAL */
                (profile_user?.desk_permissions?.entrance_option?.filter(el => el === 'p2')?.length > 0 ? true : false) &&
                <div
                    className={`flex flex-col items-start gap-1 mt-5 p-3 ${wareHouseBtn.disabled === true ? 'text-zinc-400' : ''}`}
                    style={{
                        paddingTop: 15
                    }}
                >
                    <div className='pl-[30px] flex flex-row items-center justify-start min-w-[200px]'>
                        {doorClosedBtn.disabled === false &&
                            <DoorClosed size={20} className={`min-w-[20px] ${controllerGarageState?.status === 'online' ? 'text-intec-500' : 'text-red-500'}`} />
                        }
                        {doorClosedBtn.disabled === true &&
                            <div className="loader2 border-4 border-intec-500 border-t-white w-6 h-6 ml-3 mr-0 rounded-full"></div>
                        }
                        <span
                            className={`bg-white ml-3 text-xl ${controllerGarageState?.status === 'online' ? 'text-intec-500' : 'text-red-500'}`}
                        >
                            {controllerGarageState?.status === 'online' ? doorClosedBtn.label : 'Controlador do portão offline.'}
                            <br />
                            {controllerGarageState?.status === 'online' ? ('Mantenha pressionado para ativar') : ''}
                        </span>
                    </div>


                    <LongPressButton
                        onLongPress={() => {
                            // alert('LONPRESS')
                            if (doorClosedBtn.disabled === true) {
                                return
                            }

                            setDoorClosedBtn(old => {
                                return { ...old, label: "Acionando, aguarde...", disabled: true }
                            })

                            api_bd_nr.post(`/igarage`, {
                                idUser: profile_user.id,
                                opt: 'p2',
                                autoClose
                            }).then(resp => {
                                handleOK('p2', resp.data)

                            }).catch(error => {
                                handleError('p2', error)
                            })
                        }}
                        onPressingChild={<span className='text-xl'>ACIONANDO...</span>}
                    >
                        <span className='text-xl'>ACIONAR PORTÃO</span>
                    </LongPressButton>


                    {/* <button
                        className='mt-3 p-4 w-full rounded-[6px] bg-intec-500 text-xl text-white'
                        onClick={() => {
                            if (doorClosedBtn.disabled === true) {
                                return
                            }

                            setDoorClosedBtn(old => {
                                return { ...old, label: "Acionando, aguarde...", disabled: true }
                            })

                            api_bd_nr.post(`/igarage`, {
                                idUser: profile_user.id,
                                opt: 'p2',
                                autoClose
                            }).then(resp => {
                                handleOK('p2', resp.data)

                            }).catch(error => {
                                handleError('p2', error)
                            })
                        }}
                    >
                        ACIONAR PORTÃO
                    </button> */}
                </div>
            }
        </div>
    );
}

export default DeskAutomation;