import React, {
    useState,
    useEffect
} from 'react';

import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import * as IoIcons from 'react-icons/io';

import Checkbox from '@material-ui/core/Checkbox';

import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';

import FileUploader from '../FileUploader'
import Styles from '../Styles'
import { api_bd } from '../../services/Api'

import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil'
import userState, { profileData } from '../../atoms/user.atom'
import { modalDataState } from '../../atoms/appState'

import customerData from '../../atoms/customers.atom'

import NivelDeAcesso from '../NivelDeAcesso'

import DeskPermissionTimes from '../DeskAutomation/DeskPermissions';

import { Settings2 } from 'lucide-react';

const { backend_files_address } = require('../../config.json')


function FormDataUser(props) {

    const { width, set_data_new_user, edit: profile_data } = props

    const [sgi_user, set_sgi_user] = useRecoilState(userState)

    const customers = useRecoilValue(customerData)

    const profile_user = useRecoilValue(profileData)

    const styles = Styles()

    const [data_user, set_data_user] = useState({
        nome: profile_data?.nome,
        user_glpi: profile_data?.username || profile_data?.user_glpi,
        image_profile: {
            name: '',
            filename: profile_data?.imagem || profile_data?.image_profile.filename,
            destination: ''
        },
        contato: profile_data?.contato,
        ativo: profile_data?.ativo,
        nivel: profile_data?.nivel,
        permissoes: profile_data?.permissoes || [],
        access_customers: profile_data?.access_customers || [],
        desk_permissions: profile_data?.desk_permissions || {},
        conta_master: profile_data?.conta_master || {
            masterUserName: "",
            masterUserId: ""
        }
    })

    const [submit_is_disabled, set_submit_is_disabled] = useState(false)

    const [password, set_password] = useState('')

    const [avatar_uri, set_avatar_uri] = useState(null);

    const [image_profile, set_image_profile] = useState(null);

    const [showPopUpPermissoesAcessos, setShowPopUpPermissoesAcesso] = useState(false)

    const [listAccessCustomers, setListAccessCustomers] = useState(data_user.access_customers)

    const [listClientUsers, setListClientUsers] = useState([])

    const [masterAccount, setMasterAccount] = useState(data_user.conta_master)

    const [showPopUpSelectUserMaster, setShowPopUpSelectUserMaster] = useState(false)

    const setViteModal = useSetRecoilState(modalDataState)

    const load_users = async () => {
        const lista = await api_bd.get('/users')
        const alphabetically = lista.data.filter(user => (user.ativo === true && user.nivel.match(/Cliente/))).sort((a, b) => a.nome.localeCompare(b.nome))
        try {
            setListClientUsers([...alphabetically.filter(user => (user.nivel.match(/Cliente/)))])
            // setListClientUsers([...alphabetically.filter(user => (user.nivel.match(/Cliente/)))])
        } catch (error) {   
            //console.log(error)
        }
    }

    useEffect(() => {
        /*SGI data
        {
            username: "felipe.batista", 
            nome: "Felipe E. Batista", 
            imagem: "user_88b7afea976e558f57cf659738b1d0fe-IMG_20210422_222630_559.jpg", 
            nivel: "Técnico"
        }
        */
        (async () => {
            await load_users()
        })()

        if (props.edit) {
            // console.log(profile_data)
            // set_data_user(props.edit)
            set_avatar_uri(data_user.image_profile.filename === '' ? `${backend_files_address}/user_placeholder.jpg` : `${backend_files_address}/${data_user.image_profile.filename}`)
        }

    }, [])


    // useEffect(() => {
    //     console.log(data_user)
    // }, [data_user])

    // useEffect(() => {
    //     console.log('useEffect LIST ACCESS: ', listAccessCustomers)
    // }, [listAccessCustomers])

    const handle_data_user = (key, value) => {
        let data = { ...data_user }
        data[key] = value
        set_data_user(data)
    }



    const AccessRules = () => {
        return (
            <div style={{ position: 'absolute', marginTop: 30, zIndex: 300 }} className={`${props._classname || "modal_40_70"}`}>
                <Card className={styles.card}>
                    <CardContent>
                        <div style={{ maxHeight: 650, overflowY: 'auto' }}>
                            {customers.map(customer => (
                                <div>
                                    <Checkbox
                                        checked={listAccessCustomers.find(permissao => permissao === customer._id)}
                                        onChange={(e) => {
                                            let acesso = listAccessCustomers.find(permissao => permissao === customer._id)
                                            if (acesso) {
                                                let newAccess = listAccessCustomers.filter(permissao => permissao !== customer._id)
                                                setListAccessCustomers(newAccess)
                                            } else {
                                                setListAccessCustomers(old => {
                                                    let newAccess = customer._id
                                                    return [newAccess, ...old]
                                                })
                                            }
                                        }}
                                        inputProps={{ 'aria-label': 'primary checkbox' }}
                                        color="primary"
                                    />
                                    <span style={{ fontSize: '1em' }}>{customer.nome}</span>
                                </div>
                            ))}
                        </div>
                        <Button
                            className={styles.btn_submit}
                            variant="contained"
                            color="primary"
                            size="small"
                            disabled={submit_is_disabled}
                            onClick={async () => {
                                setShowPopUpPermissoesAcesso(false)
                            }}
                        >
                            Fechar
                        </Button>
                    </CardContent>
                </Card>
            </div>
        )
    }


    const ListUsersToSelectMaster = () => {
        return (
            <div style={{ position: 'absolute', marginTop: 30, zIndex: 300 }} className={`${props._classname || "modal_40_70"}`}>
                <Card className={styles.card}>
                    <CardContent>
                        <div style={{ fontSize: '1.3em' }}><b style={{ fontSize: '1em' }}>Selecione uma Conta Master</b> (conta usada para abertura de chamados)<hr /></div>
                        <div style={{ maxHeight: 780, minHeight: 780, overflowY: 'auto', display: 'flex', flexDirection: 'column' }}>
                            {listClientUsers.map(clienteuser => (
                                <Button
                                    className={styles.btn_submit}
                                    style={{ maxWidth: 300 }}
                                    variant="contained"
                                    color="primary"
                                    size="small"
                                    disabled={submit_is_disabled}
                                    onClick={async () => {
                                        setMasterAccount({
                                            masterUserName: clienteuser.nome,
                                            masterUserId: clienteuser._id
                                        })
                                        setShowPopUpSelectUserMaster(false)
                                    }}
                                >
                                    {clienteuser.nome}
                                </Button>
                            ))}

                        </div>
                    </CardContent>
                </Card>
            </div >
        )
    }




    return (
        <div className={`${props._classname || "modal_40_70"}`}>

            {showPopUpPermissoesAcessos &&
                <AccessRules />
            }

            {showPopUpSelectUserMaster &&
                <ListUsersToSelectMaster />
            }

            {data_user && data_user.user_glpi != '' &&
                <Card className={styles.card}>
                    <CardContent>
                        <div className='flex' style={{ marginTop: 20 }}>
                            <span style={{ fontSize: '1.5em' }}>{props.edit ? "Editar Perfil" : "Adicionar Usuário"}</span>
                            {props.edit && profile_user.nivel.match(/Super/i) &&
                                <IoIcons.IoIosTrash
                                    size={26}
                                    style={{ marginBottom: -3, marginLeft: 10, cursor: "pointer" }}
                                    onClick={async () => {
                                        if (window.confirm(`Tem certeza que excluir o usuário: ${data_user.nome}`)) {
                                            try {
                                                const resp = await api_bd.delete(`/users/${props.edit._id}`)
                                                // alert(`/users/${data_user._id}`)
                                                if (set_data_new_user) {
                                                    set_data_new_user(resp.data)
                                                }
                                            } catch (error) {
                                                if (error.message.indexOf('code 400') > -1) {
                                                    alert("Ocorreu um erro tente novamente.")
                                                }
                                            }
                                        }
                                    }}
                                />
                            }
                        </div>


                        <div style={{ width: '100%', justifyContent: 'center', marginBottom: 30 }} className="flex">
                            <img
                                style={{ width: 150, height: 150, borderRadius: '50%', objectFit: 'cover' }}
                                src={props.avatar ? profile_data.nivel === 'Cliente-Admin' ? `${backend_files_address}/user_placeholder.jpg` : props.avatar : avatar_uri || `${backend_files_address}/user_placeholder.jpg`}
                            />
                        </div>

                        <NivelDeAcesso visivel_para={['Super']}>
                            <div className='w-full flex flex-row justify-center p-3'>
                                <div
                                    className='flex  justify-center items-center bg-intec text-white rounded w-[200px] p-1 cursor-pointer'
                                    onClick={() => {
                                        setViteModal({
                                            show: true,
                                            component: (<DeskPermissionTimes 
                                            load_users={load_users} 
                                            data_user={data_user} 
                                            set_data_user={set_data_user} 
                                            set_sgi_user={set_sgi_user}
                                            load_users_list={props.load_users_list}
                                            set_show_form_modal={props.set_show_form_modal}
                                            />)
                                        })

                                        //fecha o modal do usuário
                                        if(props.callback){
                                            props.callback()
                                        }
                                    }}

                                >
                                    <Settings2 size={20} />
                                    <span>Permissões para o Escritório</span>
                                </div>

                            </div>
                        </NivelDeAcesso>

                        <form className={styles.form_cad_user} noValidate autoComplete="off">
                            {(!props.edit || props.glpi) &&
                                <div style={{ width: "100%" }}>
                                    <TextField
                                        className={styles.form_input}
                                        onChange={e => {
                                            handle_data_user("user_glpi", e.target.value.toLowerCase())
                                        }}
                                        id="user_glpi"
                                        label="Login"
                                        required={true}
                                        value={data_user.user_glpi}
                                    />
                                </div>
                            }
                            {/* <NivelDeAcesso visivel_para={['Cliente', 'Cliente-Admin']}>
                                <div style={{ width: "100%" }}>
                                    <TextField
                                        className={styles.form_input}
                                        onChange={e => {
                                            handle_data_user("nome", e.target.value)
                                        }}
                                        disabled={true}
                                        id="name"
                                        label="Nome"
                                        value={data_user.nome}

                                    />
                                </div>
                            </NivelDeAcesso> */}

                            <div style={{ width: "100%" }}>
                                <TextField
                                    className={styles.form_input}
                                    onChange={e => {
                                        handle_data_user("nome", e.target.value)
                                    }}
                                    id="name"
                                    label="Nome"
                                    value={data_user.nome}

                                />
                            </div>

                            <NivelDeAcesso visivel_para={['Super', 'Técnico-Admin']}>
                                <>


                                    <div style={{ width: "100%" }}>
                                        <FormControl className={styles.form_input} style={{ marginTop: 15 }}>
                                            <InputLabel htmlFor="select-nivel">Situação &nbsp; &nbsp;  *</InputLabel>
                                            <Select
                                                style={{ width: '100%', marginTop: 20 }}
                                                value={data_user.ativo === true ? 'ativo' : 'inativo'}
                                                // defaultValue={new_date.nome}
                                                onChange={e => {
                                                    handle_data_user("ativo", e.target.value === 'ativo' ? true : false)
                                                }}
                                            >
                                                <MenuItem value="ativo">Ativo</MenuItem>
                                                <MenuItem value="inativo">Inativo</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </div>

                                    <div style={{ width: "100%" }}>
                                        <TextField
                                            className={styles.form_input}
                                            onChange={e => {
                                                if (e.target.value.length > 15) { return }
                                                let numero = e.target.value.replace(/\(|\)|-| /, '')
                                                numero = numero.replace(/^(\d{2})(\d{5})(\d{4})/, '($1) $2-$3');
                                                handle_data_user("contato", numero)
                                            }}
                                            id="contato"
                                            label="Contato"
                                            value={data_user.contato}
                                        />
                                    </div>
                                </>
                            </NivelDeAcesso>

                            <div style={{ width: "100%" }}>
                                <TextField
                                    className={styles.form_input}
                                    onChange={e => {
                                        set_password(e.target.value)
                                    }}
                                    type="password"
                                    id="senha"
                                    label={props.edit ? 'Alterar Senha' : 'Senha'}
                                    value={password}
                                />
                            </div>

                            <NivelDeAcesso visivel_para={['Super']}>
                                <div style={{ width: "100%" }}>
                                    <FormControl className={styles.form_input} style={{ marginTop: 15 }}>
                                        <InputLabel htmlFor="select-nivel">Nível &nbsp; &nbsp;  *</InputLabel>
                                        <Select
                                            value={data_user.nivel ? data_user.nivel : 'selecione'}
                                            // defaultValue={'implantacao_homologacao'}
                                            labelId="select-nivel"
                                            onChange={e => {
                                                handle_data_user("nivel", e.target.value)
                                            }}
                                        >
                                            <MenuItem value="selecione">Selecione</MenuItem>
                                            <MenuItem value="Outage-user">Outage User</MenuItem>
                                            <MenuItem value="Outage-level-1">Outage Viewer Admin</MenuItem>
                                            <MenuItem value="Cliente">Cliente</MenuItem>
                                            <MenuItem value="Cliente-Admin">Cliente Admin</MenuItem>
                                            <MenuItem value="Técnico">Técnico</MenuItem>
                                            <MenuItem value="Técnico-Admin">Administrador</MenuItem>
                                            <MenuItem value="Super">Super Administrador</MenuItem>
                                        </Select>
                                    </FormControl>
                                </div>

                                {data_user.nivel && data_user.nivel === 'Cliente-Admin' &&
                                    <>
                                        <div style={{ width: "100%", marginTop: 15, display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                            <Button
                                                className={styles.btn_submit}
                                                variant="contained"
                                                color="primary"
                                                size="small"
                                                disabled={submit_is_disabled}
                                                onClick={async () => {
                                                    setShowPopUpSelectUserMaster(true)
                                                }}
                                            >
                                                Selecionar Conta Master
                                            </Button>

                                            {(masterAccount.masterUserName !== undefined) &&
                                                <div style={{ marginLeft: 20, fontWeight: 'bolder', fontSize: '1.1em' }}>( {masterAccount.masterUserName} )</div>
                                            }

                                        </div>

                                        <div style={{ width: "100%", marginTop: 15 }}>
                                            <Button
                                                className={styles.btn_submit}
                                                variant="contained"
                                                color="primary"
                                                size="small"
                                                disabled={submit_is_disabled}
                                                onClick={async () => {
                                                    setShowPopUpPermissoesAcesso(true)
                                                }}
                                            >
                                                Permissões de Acesso Adicionais
                                            </Button>

                                        </div>
                                    </>
                                }

                                {/*                                 
                                <div style={{ width: "100%", marginTop: 15 }}>
                                    <div style={{ color: 'grey' }}>Permissões</div>
                                    <div>
                                        <Checkbox
                                            checked={data_user.permissoes.find(permissao => permissao === 'graficos')}
                                            onChange={(e) => {
                                                // console.log(e.target.checked)
                                                if (e.target.checked) {
                                                    let _permissoes = [...data_user.permissoes]
                                                    _permissoes.push('graficos')
                                                    handle_data_user("permissoes", _permissoes)
                                                    // data_user.permissoes.find(permissao => permissao === 'graficos')
                                                } else {
                                                    let _permissoes = data_user.permissoes.filter(permissao => permissao !== 'graficos')
                                                    handle_data_user("permissoes", _permissoes)
                                                }
                                                //handle_data_user("permissoes", numero)
                                            }}
                                            inputProps={{ 'aria-label': 'primary checkbox' }}
                                            color="primary"
                                        />
                                        <span style={{ fontSize: '1em' }}>Gráficos</span>
                                    </div>
                                </div> */}
                            </NivelDeAcesso>

                            <div style={{ width: "100%" }}>
                                <div style={{
                                    color: "#888888",
                                    marginLeft: 0,
                                    marginTop: 15,
                                    marginBottom: 5,
                                    top: 10
                                }}>Imagem para o perfil: {data_user.image_profile.name}</div>
                                <FileUploader
                                    set_file={set_image_profile}
                                    preview_src={(e) => {
                                        // console.log(e)
                                        set_avatar_uri(e)
                                    }}
                                />
                            </div>

                            <div style={{ width: "100%" }}>
                                <Button
                                    className={styles.btn_submit}
                                    variant="contained"
                                    color="primary"
                                    size="small"
                                    disabled={submit_is_disabled}
                                    onClick={async () => {
                                        if (data_user.nivel.match(/Cliente|Super|Téncico-Admin/)) {
                                            setMasterAccount({
                                                masterUserName: "",
                                                masterUserId: ""
                                            })
                                        }

                                        const filters = {
                                            user_glpi: "Login",
                                            nome: "Nome"
                                        }



                                        for (let f in Object.values(filters)) {
                                            let filter = Object.keys(filters)[f]
                                            if (data_user[filter] === undefined || data_user[filter] === '') {
                                                return alert(`Campo obrigatório: ${filters[filter].toUpperCase()}`)
                                            }
                                        }


                                        if (image_profile && image_profile.name !== '') {
                                            const formData = new FormData();

                                            formData.append('file', image_profile)

                                            let new_data_user = { ...data_user, conta_master: masterAccount, access_customers: listAccessCustomers }

                                            delete new_data_user.desk_permissions

                                            for (let key in new_data_user) {
                                                formData.append(key, new_data_user[key])
                                            }

                                            if (password.length > 0) {
                                                formData.append('password', password)
                                            }

                                            try {
                                                if (props.edit) {
                                                    const resp = await api_bd.put(`/users/${data_user.user_glpi}`, formData)
                                                    const edited_user_data = { ...sgi_user, sgi_data: { ...resp.data[0] } }

                                                    if (profile_user.id === edited_user_data.sgi_data._id) {
                                                        set_sgi_user(edited_user_data)
                                                    }

                                                    if (set_data_new_user) {
                                                        set_data_new_user(resp.data)
                                                    }
                                                    props.callback(resp.data[0])
                                                    alert(`Usuário editado com sucesso!\nusuário\nNome: ${data_user.nome}\nUsuário: ${data_user.user_glpi}`)

                                                } else {
                                                    const resp = await api_bd.post('/users/', formData)
                                                    alert(`Usuário cadastrado com sucesso!\nusuário\nNome: ${data_user.nome}\nUsuário: ${data_user.user_glpi}`)
                                                    if (set_data_new_user) {
                                                        set_data_new_user(resp.data)
                                                    }
                                                }
                                            } catch (error) {
                                                if (error.message.indexOf('code 400') > -1) {
                                                    alert("Verifique os dados e tente novamente.")
                                                }
                                            }

                                        } else {
                                            let dadosuser = { ...data_user, conta_master: masterAccount, access_customers: listAccessCustomers }
                                            if (password.length > 0) {
                                                dadosuser['password'] = password
                                            }
                                            try {
                                                if (props.edit) {

                                                    const resp = await api_bd.put(`/users/${data_user.user_glpi}`, dadosuser)
                                                    const edited_user_data = { ...sgi_user, sgi_data: { ...resp.data[0] } }

                                                    if (profile_user.id === edited_user_data.sgi_data._id) {
                                                        set_sgi_user(edited_user_data)
                                                    }

                                                    if (set_data_new_user) {
                                                        set_data_new_user(resp.data)
                                                    }

                                                    props.callback(resp.data[0])

                                                    if(props.load_users_list){
                                                        await props.load_users_list()
                                                    }

                                                    alert(`Usuário editado com sucesso!\nNome: ${data_user.nome}\nUsuário: ${data_user.user_glpi}`)

                                                } else {
                                                    const resp = await api_bd.post('/users', data_user)

                                                    if(props.load_users_list){
                                                        await props.load_users_list()
                                                    }

                                                    if (set_data_new_user) {
                                                        set_data_new_user(resp.data)
                                                    }

                                                    alert(`Usuário cadastrado com sucesso!\nNome: ${data_user.nome}\nUsuário: ${data_user.user_glpi}`)
                                                }
                                            } catch (error) {
                                                if (error.message.indexOf('code 400') > -1) {
                                                    alert("Verifique os dados e tente novamente.")
                                                }
                                            }
                                        }
                                    }}
                                >
                                    Salvar
                                </Button>
                            </div>
                        </form>
                    </CardContent>
                </Card>
            }
        </div>
    );
}

export default FormDataUser;

//Schema Cliente
//{"nome":"fulano","cnpj_1":"123","cnpj_2":"321","cnpj_3":"123","end":"Avenida teste","contato":"contato teste","fone_1":"fone1","fone_2":"fone2","fone_3":"fone 3","email_1":"email1","email_2":"email2","password":"senha","link_whats":"wa.me","situacao":"inativo","cliente_desde":"10/01/2020"}



