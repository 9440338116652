import React, { useState, useEffect, useRef } from 'react';

const LongPressButton = ({ onLongPress, onPressingChild, children }) => {
  const [isPressing, setIsPressing] = useState(false);
  const timerRef = useRef(null);

  const startPress = () => {
    setIsPressing(true);
    timerRef.current = setTimeout(() => {
      onLongPress();
      resetPress();
    }, 1000);
  };

  const resetPress = () => {
    setIsPressing(false);
    clearTimeout(timerRef.current);
  };

  useEffect(() => {
    return () => {
      clearTimeout(timerRef.current);
    };
  }, []);

  return (
    <button
      onMouseDown={startPress}
      onMouseUp={resetPress}
      onMouseLeave={resetPress}
      onTouchStart={startPress}
      onTouchEnd={resetPress}
      className={`mt-3 p-4 w-full rounded-[6px] ${isPressing ? 'bg-intec-600' : 'bg-intec-500'} text-xl text-white`}
      style={{
        // backgroundColor: isPressing ? 'lightgreen' : 'lightgray',
        // padding: '10px 20px',
        // border: 'none',
        // borderRadius: '5px',
        cursor: 'pointer',
      }}
    >
      {isPressing ? (onPressingChild || <span>ACIONANDO...</span>) : children}
    </button>
  );
};

export default LongPressButton;
