import React, { useState } from 'react';

import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil'
import userState, { profileData } from '../../atoms/user.atom'
import { modalDataState } from '../../atoms/appState';

import { Warehouse, DoorClosed } from 'lucide-react';

import { api_bd } from '../../services/Api';



function DeskPermissionTimes(props) {
	const week = ["Domingo", "Segunda-Feira", "Terça-Feira", "Quarta-Feira", "Quinta-Feira", "Sexta-Feira", "Sábado"];

	const [sgi_user, set_sgi_user] = useRecoilState(userState)
	const profile_user = useRecoilValue(profileData)
	const setModalData = useSetRecoilState(modalDataState)

	const [formBtnDisabled, setFormBtnDisabled] = useState(false)

	const handlePermissions = (day, option, value) => {
		let temp = [...permissions]
		const newPermissions = temp.map(el => {
			if (day === el.day) {
				if (option == "tini") {
					return ({ ...el, timeRange: [value, el.timeRange[1]] })
				} else if (option == "tend") {
					return ({ ...el, timeRange: [el.timeRange[0], value] })
				} if (option == "day") {
					return ({ ...el, permited: value })
				}
			} else {
				return el
			}
		})
		setPermissions(newPermissions)
	}

	const [entranceOptions, setEntranceOptions] = useState(props?.data_user?.desk_permissions?.entrance_option ? props?.data_user?.desk_permissions?.entrance_option : [])
	const [permissions, setPermissions] = useState((props?.data_user?.desk_permissions?.gate && props?.data_user?.desk_permissions?.gate?.length > 0) ? props?.data_user?.desk_permissions?.gate : [
		{
			day: 0,
			permited: true,
			timeRange: ["00:00", "23:59"]
		},
		{
			day: 1,
			permited: true,
			timeRange: ["00:00", "23:59"]
		},
		{
			day: 2,
			permited: true,
			timeRange: ["00:00", "23:59"]
		},
		{
			day: 3,
			permited: true,
			timeRange: ["00:00", "23:59"]
		},
		{
			day: 4,
			permited: true,
			timeRange: ["00:00", "23:59"]
		},
		{
			day: 5,
			permited: true,
			timeRange: ["00:00", "23:59"]
		},
		{
			day: 6,
			permited: true,
			timeRange: ["00:00", "23:59"]
		},
	])

	const [showOnMenu, setShowOnMenu] = useState(props?.data_user?.desk_permissions?.show_on_menu || false)

	// console.log('props?.data_user:', props?.data_user)

	return (
		<div
			className='flex flex-col space-y-4'
			style={{ zIndex: 9999 }}
		>
			<div className='flex flex-col pb-10 space-y-4'>
				<div className='flex flex-row items-center  border border-l-white border-t-white border-r-white border-b-1 border-b-intec-500'>
					<span className='font-bold text-xl p-0 m-0 w-[200px]'>Motrar no menu do usuário</span>
					<input
						className='ml-2'
						type='checkbox'
						checked={showOnMenu}
						onChange={(e) => {
							setShowOnMenu(e.target.checked)
						}}
					/>
				</div>

				<details className="w-full flex flex-col space-y-4">
					<summary className="font-bold text-xl  pt-5">Regras permitidas para:</summary>
					<div className='flex flex-row items-center'>
						<input 
						checked={entranceOptions.filter(el => el === 'p1')?.length > 0 ? true : false}
						type='checkbox' 
						className='mr-3'
						onChange={(e)=>{
							const temp = [...entranceOptions]
							if(e.target.checked === true){
								temp.push('p1')
								setEntranceOptions(temp)
							}else{
								setEntranceOptions(entranceOptions.filter(el => el !== 'p1'))
							}
						}}
						/>
						<Warehouse />
						<span>Portão Veicular</span>
					</div>
					<div className='flex flex-row items-center'>
						<input
							checked={entranceOptions.filter(el => el === 'p2')?.length > 0 ? true : false}
							type='checkbox'
							className='mr-3'
							onChange={(e)=>{
								const temp = [...entranceOptions]
								if(e.target.checked === true){
									temp.push('p2')
									setEntranceOptions(temp)
								}else{
									setEntranceOptions(entranceOptions.filter(el => el !== 'p2'))
								}
							}}
						/>
						<DoorClosed />
						<span>Portão Pedestre</span>
					</div>
				</details>
				<details className="w-full flex flex-col">
					<summary className="font-bold text-xl  pt-10">Permissões para abertura dos portões</summary>
					<h2 className='font-bold text-xl mt-5 mb-2'>Horários permitidos</h2>
					<div className='space-y-5'>
						{permissions.map(el => (
							<div key={`day_${el.day}`} className='flex flex-col border border-l-white border-t-white border-r-white border-b-1 border-b-intec-500'>
								<div className='flex flex-row items-center'>
									<span className='p-0 m-0 w-[80px]'>{week[el?.day]}</span>
									<input
										className='ml-2'
										type='checkbox'
										checked={el?.permited}
										onChange={(e) => {
											handlePermissions(el.day, 'day', e.target.checked)
										}}
									/>
								</div>
								<div className='flex flex-row gap-10'>
									<div className='flex flex-row'>
										<span className='p-0 m-0'>De</span>
										<input
											className='ml-2'
											type='time'
											value={el?.timeRange[0]}
											onChange={(e) => {
												handlePermissions(el.day, 'tini', e.target.value)
											}}
										/>
									</div>

									<div className='flex flex-row'>
										<span className='p-0 m-0'>às</span>
										<input
											className='ml-2'
											type='time'
											value={el?.timeRange[1]}
											onChange={(e) => {
												handlePermissions(el.day, 'tend', e.target.value)
											}}
										/>
									</div>
								</div>
							</div>
						))}
					</div>
				</details>
			</div>
			<button
				className={`flex justify-center items-center ${formBtnDisabled === true ? 'bg-zinc-400' : 'bg-intec'} text-white rounded w-[150px] p-2 mt-10`}
				onClick={() => {
					if (formBtnDisabled === true) {
						return
					}

					setFormBtnDisabled(true)

					api_bd.put(`/users/${props.data_user.user_glpi}`, {
						user_glpi: props.data_user.user_glpi,
						nome: props.data_user.nome,
						"desk_permissions.show_on_menu": showOnMenu,
						"desk_permissions.entrance_option": entranceOptions,
						"desk_permissions.gate": permissions
					})
						.then(async (resp) => {

							const edited_user_data = { ...sgi_user, sgi_data: { ...resp.data[0] } }

							if (profile_user.id === edited_user_data.sgi_data._id) {
								set_sgi_user(edited_user_data)
							}

							if (props.load_users_list) {
								await props.load_users_list()
							}

							if (props.set_show_form_modal) {
								props.set_show_form_modal()
							}

							setFormBtnDisabled(false)

							setModalData({ show: false, component: <></> })
						}).catch(error => {
							alert('Ocorreu um erro :(, tente novamente mais tarde')
							setFormBtnDisabled(false)
						})
				}}
			>
				SALVAR
			</button>
		</div>
	);
}

export default DeskPermissionTimes;