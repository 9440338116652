import React, { useEffect, useState } from 'react';

import { slider, outerContainer } from './swipeToConfirm.module.css';

function SwipeToConfirm(props) {
    const [value, setValue] = useState(0)



    return (
        <div className={`${outerContainer} ${(value > 80) ? 'bg-green-500' : props.disabled ? 'text-zinz-400 bg-zinc-400': 'bg-intec-500'} rounded-[6px]`}>
            <input
            className={slider}
                type="range" 
                min="0" 
                max="100" 
                value={value}
                onMouseDown={(e)=>{
                    console.log('OPA', e.target.value)
                }}

                onChange={(e)=>{
                    if(props.disabled){
                        return
                    }

                    setValue(e.target.value)
                    console.log()
                }}

                onMouseUp={(e)=>{
                    if(props.onOk && value > 80){
                        if(!props.disabled){
                            props.onOk()
                        }
                    }
                    setValue(0)
                }}

                onTouchEnd={(e)=>{
                    if(props.onOk && value > 80){
                        if(!props.disabled){
                            props.onOk()
                        }
                    }
                    setValue(0)
                }}


                onBlur={(e)=>{
                    if(props.onOk && value > 80){
                        if(!props.disabled){
                            props.onOk()
                        }
                    }
                    setValue(0)
                }}
                
            />
            <span className='text-white text-xl'>{value > 80 ? 'CONFIRMADO' : 'ARRASTE PARA ACIONAR' }</span>
        </div>
    );
}

export default SwipeToConfirm;